import gql from 'graphql-tag'
import { apolloClient } from '@/vue-apollo-public'
import { apolloClient as apolloPrivate } from '@/vue-apollo'

export const getTravellerProfile = async ({ token, confirmationNumber }) => {
  const response = await apolloClient.mutate({
    mutation: gql`
      query($token: String!, $confirmationNumber: Int!) {
        publicTravellerProfile(token: $token, confirmationNumber: $confirmationNumber, languaje: "es") {
          id,
          dni,
          firstName,
          lastName,
          gender,
          birthday,
          nationality,
          avatarUrl,
          physicalState,
          fitnessIntensity,
          hasFoodReq,
          hasMedicalReq,
          foodRestrictions
          otherMedicalConditions
          lang
          reservationConsulted(confirmationNumber: $confirmationNumber) {
            id,
            confirmationNumber,
            partyCode,
            legNumber,
            reservationStatus,
            arrivalTransportId,
            arrivalTransportLocation,
            arrivalTransportCarrier,
            arrivalTransportType,
            arrivalTransportDatetime,
            arrivalStatus,
            arrivalOtherHotel,
            arrivalDestinationType,
            departureTransportId,
            departureTransportLocation,
            departureTransportCarrier,
            departureTransportType,
            departureTransportDatetime,
            departureStatus,
            departureDestinationType,
            departureOtherHotel,
            interestComment
            termsAccepted
            termsCovidAccepted
            termsConnectsAccepted
            termsTsrAccepted
            checkin
            checkout
            travellerType
            exploraConnectId
            packagesCodes
            partyConnectsCode
            crossingGroup
            includesMapi
            mapiVisit {
              preferredDate
            }
            partyConfirmations {
              confirmationNumber
              travellerObj {
                firstName
                lastName
              }
            }
            steps {
              id
              step
              legNumber
              status
            }
            hotel {
              id
              name
              termsAndConditions(lang: "es")
              termsAndConditionsCovid(lang: "es")
              termsAndConditionsConnect(lang: "es")
              termsAndConditionsTsr(lang: "es")
              airlines {
                code
                name
              }
              maxTimeIn
              maxTimeOut
              transferMsgEsIn
              transferMsgEsOut
            }
          }
          aditionalReservations(confirmationNumber: $confirmationNumber) {
            id
            confirmationNumber,
            legNumber,
            arrivalTransportId,
            arrivalTransportLocation,
            arrivalTransportCarrier,
            arrivalTransportType,
            arrivalTransportDatetime,
            arrivalStatus,
            arrivalOtherHotel,
            departureTransportId,
            departureTransportLocation,
            departureTransportCarrier,
            departureTransportType,
            departureTransportDatetime,
            departureStatus
            departureOtherHotel
            interestComment
            termsAccepted
            termsCovidAccepted
            termsTsrAccepted
            checkin
            checkout
            travellerType
            packagesCodes
            exploraConnectId
            crossingGroup
            specialTransportCrossing
            hotel{
              id
              name
              termsAndConditions(lang: "es")
              termsAndConditionsCovid(lang: "es")
              airlines {
                code
                name
              }
              maxTimeIn
              maxTimeOut
              transferMsgEsIn
              transferMsgEsOut
            }
          }
          interests{
            id,
            iconName,
            code,
            name,
            clasification
          },
          address {
            country,
            region,
            zipCode,
            operaId,
            city,
            address
          }
          contacts {
            operaId,
            contactType,
            contactRole,
            value
          }
          tags{
            id,
            category,
            subCategory,
            value
          }
          aboutUsAnswer
          foodRequirements {
            id
            code
            name
            haveRelation
          }
          medicalConditions {
            id
            code
            name
            haveRelation
          }
        }
      }
    `,
    variables: {
      token: token,
      confirmationNumber: confirmationNumber
    }
  })

  return response.data
}

export const getNationalities = async () => {
  const response = await apolloClient.mutate({
    mutation: gql`
    query {
      nationalities(lang: "es") {
        value
        description
      }
    }
    `,
    variables: null
  })

  return response.data
}

export const getInformation = async ({ informationType, countryCode }) => {
  const response = await apolloClient.mutate({
    mutation: gql`
      query($informationType: String!, $countryCode: String!) {
        information(informationType: $informationType, countryCode: $countryCode) {
          value,
          description
        }
      }
    `,
    variables: {
      informationType: informationType,
      countryCode: countryCode
    }
  })

  return response.data
}

export const saveTravellerProfileData = async ({
  id, dni, name, lastName, gender, birthdate, nationality,
  country, state, city, address, email, phoneNumber,
  operaIdEmail, operaIdAddress, operaIdPhoneNumber, confirmationNumber, babySeat, provisoryId
}) => {
  const response = await apolloClient.mutate({
    mutation: gql`
      mutation(
        $id: ID!,
        $dni: String!,
        $confirmationNumber: Int!,
        $name: String!,
        $lastName: String!,
        $gender: String!,
        $birthdate: String!,
        $nationality: String!,
        $country: String!,
        $state: String!,
        $city: String!,
        $address: String!,
        $email: String!,
        $phoneNumber: String!,
        $operaIdEmail: String!,
        $operaIdAddress: String!,
        $operaIdPhoneNumber: String!,
        $babySeat: String,
        $provisoryId: Int,
        ) {
        UpdateTravellerProfile(
          travellerProfile: {
            id: $id,
            dni: $dni,
            confirmationNumber: $confirmationNumber
            name: $name,
            lastName: $lastName,
            gender: $gender,
            birthdate: $birthdate,
            nationality: $nationality,
            country: $country,
            state: $state,
            city: $city,
            address: $address,
            email: $email,
            phoneNumber: $phoneNumber,
            operaIdEmail: $operaIdEmail,
            operaIdAddress: $operaIdAddress,
            operaIdPhoneNumber: $operaIdPhoneNumber,
            babySeat: $babySeat,
            provisoryId: $provisoryId
          }
        ){
          result
        }
      }
    `,
    variables: {
      id: id,
      dni: dni,
      confirmationNumber: confirmationNumber,
      name: name,
      lastName: lastName,
      gender: gender,
      birthdate: birthdate,
      nationality: nationality,
      country: country,
      state: state,
      city: city,
      address: address,
      email: email,
      phoneNumber: phoneNumber,
      operaIdEmail: operaIdEmail,
      operaIdAddress: operaIdAddress,
      operaIdPhoneNumber: operaIdPhoneNumber,
      babySeat: babySeat,
      provisoryId: provisoryId,
    }
  })

  return response.data
}

export const getHotelTransports = async ({ confirmationNumber }) => {
  const response = await apolloClient.mutate({
    mutation: gql`
    query {
      hotelTransportInformation(confirmationNumber: ${confirmationNumber}){
        id,
        locationCode,
        transportType,
        value,
        transportTypeOws,
        cityCode,
        hotel {
          id
        },
        transportSchedule {
          timeStart
          timeEnd
          timePickup
          scheduleType
        }
      }
    }
    `,
    variables: null
  })
  return response.data
}

export const updateTransportRequirements = async ({
  travellerId,
  confirmationNumbers,
  transportId,
  transportType,
  carrierCode,
  locationCode,
  otherHotel,
  date,
  queryType,
  legNumber,
  warning,
  destinationType
}) => {
  const response = await apolloClient.mutate({
    mutation: gql`
      mutation(
        $travellerId: ID!,
        $confirmationNumbers: [Int]!
        $transportId: String!,
        $transportType: String!,
        $carrierCode: String!,
        $locationCode: String!,
        $otherHotel: String,
        $date: String!,
        $queryType: String!,
        $legNumber: Int!,
        $warning: Boolean!,
        $destinationType: String!
      ) {
        ModifyTransportRequirements(
          travellerId: $travellerId,
          confirmationNumbers: $confirmationNumbers,
          legNumber: $legNumber,
          warning: $warning,
          transportInformation: {
            transportId: $transportId,
            transportType: $transportType,
            carrierCode: $carrierCode,
            locationCode: $locationCode,
            otherHotel: $otherHotel,
            date: $date,
            queryType: $queryType,
            destinationType: $destinationType
          }
        )
        {
          result
        }
      }
    `,
    variables: {
      travellerId: travellerId,
      confirmationNumbers: confirmationNumbers,
      transportId: transportId,
      transportType: transportType,
      carrierCode: carrierCode,
      locationCode: locationCode,
      otherHotel: otherHotel,
      date: date,
      queryType: queryType,
      legNumber: legNumber,
      warning: warning,
      destinationType: destinationType
    }
  })
  return response.data
}

export const ReservationCancellTransport = async ({
  travellerId,
  confirmationNumber,
  legNumber,
  queryType,
  checkin,
  arrivesToLunch
}) => {
  const response = await apolloClient.mutate({
    mutation: gql`
      mutation(
        $travellerId: ID!,
        $confirmationNumber: Int!,
        $legNumber: Int!,
        $queryType: String!,
        $checkin: String!,
        $arrivesToLunch: Boolean!
      ) {
        CancellTransport(
          travellerId: $travellerId,
          confirmationNumber: $confirmationNumber,
          legNumber: $legNumber,
          queryType: $queryType,
          checkin: $checkin,
          arrivesToLunch: $arrivesToLunch,
        )
        {
          result
        }
      }
    `,
    variables: {
      travellerId: travellerId,
      confirmationNumber: confirmationNumber,
      legNumber: legNumber,
      queryType: queryType,
      checkin: checkin,
      arrivesToLunch: arrivesToLunch
    }
  })
  return response.data
}

export const getTagsConditions = async () => {
  const response = await apolloClient.mutate({
    mutation: gql`
      query {
        tagsConditions{
          id,
          category,
          subCategory,
          value
        }
      }
    `,
    variables: null
  })
  return response.data
}

export const saveTravellerConditions = async ({
  travellerId,
  hasFoodReq, hasMedicalReq,
  foodRequirements,
  medicalConditions,
  foodAllergiesTags,
  medicalDiseaseTags,
  medicalInjuryTags,
  medicalSurgeryTags,
  medicalAllergiesTags,
  confirmationNumber,
  foodRestrictions,
  otherMedicalConditions
}) => {
  const response = await apolloClient.mutate({
    mutation: gql`
      mutation(
        $id: ID!,
        $hasFoodReq: Boolean,
        $hasMedicalReq: Boolean,
        $foodRequirements: [ConditionsInput],
        $medicalConditions: [ConditionsInput],
        $foodAllergiesTags: [TagInput],
        $medicalDiseaseTags: [TagInput],
        $medicalInjuryTags: [TagInput],
        $medicalSurgeryTags: [TagInput],
        $medicalAllergiesTags: [TagInput],
        $confirmationNumber: Int!,
        $foodRestrictions: String!,
        $otherMedicalConditions: String!
      ) {
        UpdateTravellerConditions(
          travellerConditions: {
            id: $id,
            hasFoodReq: $hasFoodReq,
            hasMedicalReq: $hasMedicalReq,
            foodRequirements: $foodRequirements,
            medicalConditions: $medicalConditions,
            foodAllergiesTags: $foodAllergiesTags,
            medicalDiseaseTags: $medicalDiseaseTags,
            medicalInjuryTags: $medicalInjuryTags,
            medicalSurgeryTags: $medicalSurgeryTags,
            medicalAllergiesTags: $medicalAllergiesTags,
            foodRestrictions: $foodRestrictions,
            otherMedicalConditions: $otherMedicalConditions
          }
          confirmationNumber: $confirmationNumber
        ){
          result
        }
      }
    `,
    variables: {
      id: travellerId,
      hasFoodReq: hasFoodReq,
      hasMedicalReq: hasMedicalReq,
      foodRequirements: foodRequirements,
      medicalConditions: medicalConditions,
      foodAllergiesTags: foodAllergiesTags,
      medicalDiseaseTags: medicalDiseaseTags,
      medicalInjuryTags: medicalInjuryTags,
      medicalSurgeryTags: medicalSurgeryTags,
      medicalAllergiesTags: medicalAllergiesTags,
      confirmationNumber: confirmationNumber,
      foodRestrictions: foodRestrictions,
      otherMedicalConditions: otherMedicalConditions
    }
  })

  return response.data
}

export const saveTravellerFitnessConditions = async ({
  travellerId,
  confirmationNumber,
  physicalState,
  fitnessIntensity,
  provisoryId
}) => {
  const response = await apolloClient.mutate({
    mutation: gql`
      mutation(
        $travellerId: ID!,
        $confirmationNumber: Int!,
        $physicalState: Int!,
        $fitnessIntensity: Int!,
        $provisoryId: Int,
      ) {
        UpdatePhysicalConditions(
          travellerId: $travellerId,
          confirmationNumber: $confirmationNumber,
          physicalState: $physicalState,
          fitnessIntensity: $fitnessIntensity,
          provisoryId: $provisoryId
        ){
          result
        }
      }
    `,
    variables: {
      travellerId: travellerId,
      confirmationNumber: confirmationNumber,
      physicalState: physicalState,
      fitnessIntensity: fitnessIntensity,
      provisoryId: provisoryId
    }
  })

  return response.data
}

export const getInterest = async ({ confirmationNumber }) => {
  const response = await apolloClient.mutate({
    mutation: gql`
      query {
        travellerInterests(confirmationNumber: ${confirmationNumber}, languaje: "es}"){
          id,
          code,
          name,
          iconName,
          clasification
        }
      }
    `,
    variables: null
  })
  return response.data
}

export const updateInterests = async ({
  travellerId,
  confirmationNumber,
  travellerInterests,
  commentary,
  provisoryId
}) => {
  const response = await apolloClient.mutate({
    mutation: gql`
      mutation(
        $travellerId: ID!,
        $confirmationNumber: Int!
        $travellerInterests: [TravellerInterests]
        $commentary: String!,
        $provisoryId: Int,
      ) {
        UpdateTravellerInterests(
          travellerId: $travellerId,
          confirmationNumber: $confirmationNumber
          travellerInterests: $travellerInterests
          commentary: $commentary
          provisoryId: $provisoryId
        )
        {
          result
        }
      }
    `,
    variables: {
      travellerId: travellerId,
      confirmationNumber: confirmationNumber,
      travellerInterests: travellerInterests,
      commentary: commentary,
      provisoryId: provisoryId
    }
  })
  return response.data
}

export const mutationUpdateTravellerDataMapi = async ({
  confirmationNumber, id, dni, name, lastName, birthdate, nationality, provisoryId
}) => {
  const response = await apolloClient.mutate({
    mutation: gql`
      mutation(
        $id: ID!,
        $dni: String!,
        $confirmationNumber: Int!,
        $name: String!,
        $lastName: String!,
        $birthdate: String!,
        $nationality: String!,
        $provisoryId: Int,
      ) {
        UpdateTravellerDataMapi(
          id: $id,
          dni: $dni,
          confirmationNumber: $confirmationNumber,
          name: $name,
          lastName: $lastName,
          birthdate: $birthdate,
          nationality: $nationality,
          provisoryId: $provisoryId,
        ) { result }
      }
    `,
    variables: {
      id: id,
      dni: dni,
      confirmationNumber: confirmationNumber,
      name: name,
      lastName: lastName,
      birthdate: birthdate,
      nationality: nationality,
      provisoryId: provisoryId,
    }
  })

  return response.data
}

export const getCompanions = async ({ confirmationNumber }) => {
  const response = await apolloClient.mutate({
    mutation: gql`
      query($confirmationNumber: Int!) {
        reservationsCompanions (confirmationNumber: $confirmationNumber) {
          confirmationNumber
          tokenTravellerProfile
          travellerObj {
            firstName
            lastName
            avatarUrl
          }
        }
      }
    `,
    variables: { confirmationNumber }
  })
  return response.data
}

export const reservationProvisoryData = async ({ reservationId, status }) => {
  const response = await apolloPrivate.mutate({
    mutation: gql`
      query($reservationId: Int!, $status: String) {
        reservationProvisoryData (reservationId: $reservationId, status: $status) {
          id
          step
          stepValue
          dataJson
          status
          statusValue
          date
          reservation {
            confirmationNumber
          }
        }
      }
    `,
    variables: { reservationId, status }
  })
  return response.data
}
